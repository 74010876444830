import React from "react";
import { admin_mail } from "../../settings";

/**
 * Footer component displaying the site's footer information.
 * Includes the admin email address for contact purposes.
 *
 * @component
 * @example
 * return (
 *   <Footer />
 * )
 * @returns {React.JSX.Element} The rendered footer component.
 */
const Footer: React.FC = (): React.JSX.Element => {
  return (
    <footer>
      <address>
        <span className="copyleft">©</span>
        <a href={`mailto:${admin_mail}`}>PZSzach</a>
        2024
      </address>
    </footer>
  );
};

export default Footer;
